var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:".container"},[_c('Header'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"spaceFinal"}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"banner"},[_c('div',[_c('p',[_vm._v("Branding")])]),_c('h1',[_vm._v(" Litesites ")]),_c('p',[_vm._v(" Extensión de formulario para clientes "),_c('br',{staticClass:"only-mov"}),_vm._v(" potenciales "),_c('br',{staticClass:"only-pc"}),_vm._v(" en Google Ads: Búsqueda,"),_c('br',{staticClass:"only-mov"}),_vm._v(" Display, Youtube y "),_c('br',{staticClass:"only-pc"}),_vm._v("Discovery ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"parallax"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',[_c('p',[_vm._v("El reto")]),_c('h2',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',{staticClass:"only-mov"},[_vm._v(" ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, pero para el primer mes de confinamiento ya no hallábamos qué hacer con nuestras vidas. Y es por eso que me terminé haciendo adicta a las aplicaciones… y no se hagan, ustedes también. "),_c('br'),_c('br'),_vm._v(" No los culpo, de hecho se transformaron en una especie de “alivio” durante estos meses difíciles. ")])]),_c('div',[_c('picture',{staticClass:"spcelyr"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/logoLS.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/logoLS.png"),"alt":"Logo LiteSites","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('picture',{staticClass:"beforePalette only-pc"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/ls2.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/ls2.png"),"alt":"Imagen Decorativa"}})]),_c('iframe',{staticClass:"o-mv",attrs:{"src":"","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pallete"},[_c('h2',[_vm._v("Color Palette")]),_c('div',[_c('p',[_vm._v("GUM")]),_c('h3',[_vm._v("#ED669D")])]),_c('div',[_c('p',[_vm._v("BRIGHT YELLOW")]),_c('h3',[_vm._v("#E9E230")])]),_c('div',[_c('p',[_vm._v("TRUE RED")]),_c('h3',[_vm._v("#F43F37")])]),_c('div',[_c('p',[_vm._v("EMERALD")]),_c('h3',[_vm._v("#1D7721")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico__video"},[_c('iframe',{staticClass:"o-pc",attrs:{"src":"","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}}),_c('iframe',{staticClass:"o-mv",attrs:{"src":"","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico"},[_c('div',{staticClass:"video__uno"},[_c('picture',[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/ls3.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/ls3.png"),"alt":"Imagen LiteSites","loading":"lazy"}})])]),_c('div',{staticClass:"mosaico__stack"},[_c('picture',[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/ls4.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/ls4.png"),"alt":"Imagen LiteSites","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"final"},[_c('h2',[_vm._v("Extensión de formulario para clientes potenciales en Google "),_c('br',{staticClass:"o-pc"}),_vm._v(" Ads: Búsqueda, Display, Youtube y Discovery")]),_c('p',[_vm._v("¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y "),_c('br',{staticClass:"o-pc"}),_vm._v(" creo que no se van a oponer, pero para el primer mes.")]),_c('div',{staticClass:"o-pc"},[_c('picture',{staticClass:"o-pc"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/ls5.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/ls5.png"),"alt":"Imagen LitesSites","loading":"lazy"}})])]),_c('div',{staticClass:"o-mv"},[_c('picture',{staticClass:"o-mv"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/finalLSmv.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/finalLSmv.png"),"alt":"Imagen LitesSites","loading":"lazy"}})])])])])
}]

export { render, staticRenderFns }